<template>


    <div class="container text-center">
        <h2 class="display-3 bebas-neue-regular mb-5 mt-3" style="color: #F9B233;">Blog</h2>
        <div class="row">

            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome title="La Revolución de la IA Generativa" imageSrc="noti5.jpg"   idArticulo="3" 
                description="Microsoft amplía su inversión en Inteligencia Artificial..."/>
            </div>
            <!-- --------------------------------- -->

            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome
                    title="Apple Vision Pro"
                    imageSrc="noti4.jpeg" description="Redefiniendo la Computación Espacial..."   idArticulo="5" />
            </div>
            <div class="col-sm-4 col-12 noticia">
                <NoticiaCardHome
                    title="La Carrera por la Computación Cuántica"
                    imageSrc="noti6.jpg" description="Google alcanza nuevo hito..."  idArticulo="6" />

            </div>
        </div>
    </div>

    
</template>

<script>
// import NoticiaCard from './NoticiaCard.vue'
import NoticiaCardHome from '@/components/NoticiaCardHome.vue';

export default {
    components: {
        NoticiaCardHome
    }
}
</script>


<style scoped>
.overlay {
    background-color: rgb(0, 0, 0);
}

.noticia {
    display: flex;
    justify-content: center;
    padding: 0;
    padding: 10px;
    /* height: 50vh; */
}

.text-justify {
  text-align: justify;
}
</style>