<template>
  <footer class="d-flex flex-column align-items-center justify-content-center mt-5">
    <div class="footer-top">
      <div class="footer-logo mt-3">
        <img src="@/assets/logo.png" alt="WhatsApp Logo" class="logo m-2" style="filter: invert(1);">
      </div>

    </div>
    <div class="footer-middle row p-2 p-sm-0">
      <div class="footer-section order-2 order-sm-1 col-lg-3 col-6">
        <h3>¿Quiénes somos?</h3>
        <ul>
          <li><a href="/quienes-somos" onclick="closef()" class="enlace">Acerca de nosotros</a> </li>
     
        </ul>
      </div>
      <div class="footer-section order-1 order-sm-2 col-lg-3 col-6">
        <h3>Servicios</h3>
        <ul>
          <li><a href="/servicios" onclick="closef()" class="enlace">Creación de Página Web</a> </li>
          <li><a href="/servicios" onclick="closef()" class="enlace">Desarrollo de software</a> </li>
          <li><a href="/servicios" onclick="closef()" class="enlace">Venta de Hardware</a> </li>

        </ul>
      </div>
      <div class="footer-section order-4 order-sm-3 mt-2 mt-sm-0 mapa col-lg-3 col-6">


      </div>
      <div class="footer-section order-3 mt-2 mt-sm-0 order-sm-4 col-lg-3 col-6">
        <h3>Contáctanos</h3>
        <ul>
          <li class="info-dir">Tegucigalpa, Honduras</li>
          <li class="info-dir">axumhn@gmail.com</li>
          <li class="info-dir">info@axum.hn</li>
          <li class="info-dir">9456-5133</li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom mb-3">
      <p class="ms-3 ms-sm-0">2024 © AXUM</p>
      <ul class="footer-links">
        <!-- <li>Condiciones y Política de privacidad</li> -->
        <!-- <li>Mapa del sitio</li> -->
      </ul>
      <div class="social-icons">
        <a href="https://www.instagram.com/axum_pc/" target="_blank" class="fa-brands fa-instagram"></a>
      </div>
      <!-- <button class="language-button">
        español
        <i class="fas fa-chevron-down"></i>
      </button> -->
    </div>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      text: ''
    }
  },

}

</script>

<style scoped>
footer {
  /* margin-top: 60px; */
  background-color: #1c1e21;
  color: white;
  min-height: 3em;
  font-size: 0.8em;
}

.footer {
  background-color: #1c1e21;
  color: #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-logo .logo {
  width: 150px;
}

.download-button {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.download-button i {
  margin-left: 10px;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-section {
  /* flex: 1; */
  /* margin: 0 20px; */
  padding: 0 20px;
}

.footer-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #ffffff;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
  color: #a9a9a9;
  cursor: pointer;
}

.footer-section li:hover {
  text-decoration: underline;
}

.footer-bottom {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #333;
  padding-top: 20px;
}

.footer-links {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  margin-right: 20px;
  cursor: pointer;
  color: #a9a9a9;
}

.footer-links li:hover {
  text-decoration: underline;
}

.social-icons i {
  font-size: 1.5em;
  margin-right: 15px;
  color: #a9a9a9;
  cursor: pointer;
}

.social-icons i:hover {
  color: #ffffff;
}

.language-button {
  background-color: transparent;
  color: #e0e0e0;
  border: 1px solid #ffffff;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.language-button i {
  margin-left: 5px;
}

iframe{
  width: 100%;
  height: 100%;
}

.enlace{
  text-decoration: none;
  color: #a9a9a9;
}
.fa-brands{
  font-size: 1.5em;
  margin-right: 15px;
  color: #a9a9a9;
  cursor: pointer;
  text-decoration: none;
}

.info-dir:hover{
  text-decoration: none;
  cursor: text;
}
.info-dir{
  text-decoration: none !important;
  cursor: text;
}
</style>
