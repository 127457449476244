<template>
    <!-- <section class="section-hero"> -->
    <section class="section-hero ps-2 pe-2 ps-sm-5 pe-sm-5">
        <div id="carouselExampleFade" class="carousel slide " data-bs-ride="carousel">
            <div class="carousel-inner">
                <div style="height: 50%;" class="carousel-item active">
                    <img src="@/assets/img2.jpg" class="d-block w-100" alt="AHDECO PORTADA 1">
                </div>
                <!-- <div class="carousel-item">
                    <img src="@/assets/img3-opt.jpg" class="d-block w-100" alt="AHDECO PORTADA 2">
                </div> -->

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <!-- <div class="d-flex justify-content-center linea-fondo">
            <img src="@/assets/img1.jpg" class=" w-80 w-sm-100 d-sm-block" alt="">
        </div> -->

    </section>

    <section id="slogan" class="hidden ">
        <div class="container">
            <div class="row p-lg-5 p-sm-0">
                <h3 class="mt-5 bebas-neue-regular mb-5 text-center display-3 display-sm-5">Soluciones Tecnológicas a la
                    Medida de tus Necesidades</h3>


            </div>

        </div>
    </section>



    <section class="empleos row">
        <div class="col-sm-6 col-12 d-flex justify-content-center align-items-center">
            <div class="text-empleos">

                <p>
                    <strong>AXUM</strong> es una empresa especializada en <strong>desarrollo de software</strong> y
                    <strong>venta de hardware</strong>, ofreciendo <strong>soluciones tecnológicas a medida</strong>
                    para empresas y particulares. Con un enfoque en <strong>innovación</strong> y <strong>servicio al
                        cliente</strong>, AXUM proporciona herramientas que impulsan <strong>crecimiento</strong> y
                    <strong>eficiencia</strong>.
                </p>
                <p>
                    Somos el <strong>socio ideal</strong> para desarrollar aplicaciones personalizadas, mejorar
                    infraestructuras o adquirir <strong>hardware confiable</strong> y de última generación.
                </p>

            </div>

        </div>
        <div class="col-sm-6 col-12 cuadro d-flex align-items-center">
            <img src="@/assets/img-programando.png" class=" img-empleos hidden ps-3 pe-3 ps-sm-0 pe-sm-0"
                id="img-empleos" alt="EMPLEOS AHDECO">

        </div>
    </section>

    <section id="noticia" class="hidden  m-1 p-1 p-sm-5 p-sm-5 text-center">

        <h2 class="display-5 bebas-neue-regular m-5">Últimas Noticias</h2>
        <div class="row">
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 mb-5 p-0 p-sm-4">
                <NoticiaCardHome class="" imageSrc="noti5.jpg" title="La Revolución de la IA Generativa" description="Microsoft amplía su inversión en Inteligencia Artificial... " idArticulo="3" />
            </div>
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 mb-5 p-0 p-sm-4">
                <NoticiaCardHome imageSrc="noti4.jpeg" title="Apple Vision Pro"
                    description="Redefiniendo la Computación Espacial..."
                    idArticulo="5" />
            </div>
            <div class="col-sm-4 col-12 d-flex justify-content-center mb-sm-0 p-0 p-sm-4">
                <NoticiaCardHome imageSrc="noti6.jpg"
                    title="La Carrera por la Computación Cuántica"
                    description="Google alcanza nuevo hito..." idArticulo="6" />
            </div>
        </div>

    </section>





</template>

<script>

import NoticiaCardHome from "@/components/NoticiaCardHome.vue";


export default {
    name: 'BodyApp',
    mounted() {

    },
    components: {
        NoticiaCardHome,
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Mobile */
@media only screen and (max-width: 768px) {

    .hide-on-mobile {
        display: none;
    }

}

.linea-fondo {
    /* background-image: url('@/assets/blur.jpg');
            background-size: cover;
            background-position: center; */
    background-color: rgb(223, 222, 222);
}

.img-empleos {
    width: 40%;
    border-radius: 5px;
    position: absolute;
    right: 250px;
    /* top: 1750px; */
    margin-top: 20px;

}

.carousel-inner {
    border-radius: 20px;
}



.empleos {
    margin-top: 80px;
    height: 62vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */

}

.cuadro {
    background-color: rgb(225, 225, 225);
    height: 100%;
    border-radius: 5px;

}

strong {
    font-weight: 700;
}

.text-empleos {
    margin: 0px 120px 0px 50px;
    text-align: justify;

}

video {
    border-radius: 7px;
}

#mapa {
    height: 760px;

}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    /* color: #F9B233; */
    color: #F9B233;
}


/* Estilo para pantallas más pequeñas */
@media only screen and (max-width: 1200px) {
    #mapa {
        height: 66vh;
        /* 60% de la altura de la ventana */
    }

    .text-empleos {
        margin: 20px;
        text-align: justify;

    }

    .img-empleos {
        width: 100%;
        /* border-radius: 5px; */
        position: relative;
        right: 0px;
        /* top: 1750px; */
        /* margin-top: 30px; */

    }

    #noticia {
        margin: 30px !important;
        padding-inline: 0px !important;
    }

    .empleos {
        margin-top: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        /* justify-content: center; */

    }

    .cuadro {
        background-color: #F7F7F8;
        height: 0%;
        border-radius: 5px;

    }


    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-top: var(--bs-gutter-y);
    }
}

@media only screen and (max-width: 990px) {
    #mapa {
        height: 50vh;
        /* 50% de la altura de la ventana */
    }


}

@media only screen and (max-width: 767px) {
    #mapa {
        height: 36vh;
        /* 40% de la altura de la ventana */
    }


}

@media only screen and (max-width: 572px) {
    #mapa {
        height: 38vh;
        /* 30% de la altura de la ventana */
    }
}

@media only screen and (max-width: 470px) {
    #mapa {
        height: 30vh;
        /* 30% de la altura de la ventana */
    }
}

.mapa-contenedor {
    padding-bottom: 150px;
    padding-inline: 10px;
}
</style>
