<template>
  <router-link class="text-decoration-none router" :to=" '/articulo/' + idArticulo">
    <div class="noticia-card-home">
      <div class="image-wrapper">
        <img :src="computedImageSrc" class="img-noti" alt="news image" />
      </div>
      <div class="content">
        <p class="category">{{ category }}</p>
        <h2 class="title">{{ title }}</h2>
        <p class="description ">{{ description }}</p>
      </div>
    </div>
  </router-link>
  </template>
  
  <script>
  export default {
    name: "NoticiaCardHome",
    props: {
      imageSrc: {
        type: String,
        required: true,
      },
      category: {
        type: String,
        default: "Blog",
      },
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        default: ''
      },
      idArticulo:{
      type: String,
      default: '0'
    }
    },
    computed: {
      computedImageSrc() {
        return require(`@/assets/${this.imageSrc}`);
      },
    },
  };
  </script>
  
  <style scoped>
  .noticia-card-home {
    max-width: 400px;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .image-wrapper {
    overflow: hidden;
    position: relative;

    height: 27vh;
  }
  
  .image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-wrapper:hover img {
    transform: scale(1.1);
  }
  
  .content {
    padding: 16px;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
    background-color: white;
  }
  
  .category {
    color: #4a90e2;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 8px;
  }
  
  .description {
    font-size: 16px;
    color: #666;
  }

  .img-noti{
    height: 40vh;
  }

  .router{
    color: black;
  }

  .text-justify {
  text-align: justify;
}
  </style>
  