<template>
    <div class="container mapa-contenedor">
        <div class="row">
            <div
                class="col-sm-12 col-12 pb-5 contactanos text-center d-flex align-items-center flex-column justify-content-center ">
                <div class="formulario mt-5">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Correo Electrónico</label>
                        <input type="email" class="form-control" v-model="email" id="email"
                            placeholder="usuario@ejemplo.com" required>

                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Mensaje</label>
                        <textarea v-model="contenido" class="form-control" id="contenido" rows="3"></textarea>
                    </div>
                    <button class="btn btn-primary" type="button" @click="enviarCorreo()">Enviar Correo</button>
                </div>

                <p class="mt-4">Tegucigalp,Honduras</p>
                <p class="mt-4">axumhn@gmail.com</p>
                <p>info@axum.hn</p>
                <p>9456-5133</p>
            </div>

        </div>

    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            contenido: "",
            email: "",
        }
    },
    components: {
        
    },
    methods: {
        enviarCorreo() {
            let email = document.getElementById("email");
            let contenido = document.getElementById("contenido");

            // Validar el email
            if (!this.esCorreoValido(email.value)) {
                email.style.border = "2px solid #E94434";
            } else {
                email.style.border = ""; // Reinicia el borde si es válido
            }

            // Validar el contenido
            if (!this.tieneMinimoTresLetras(contenido.value)) {
                contenido.style.border = "2px solid #E94434";
            } else {
                contenido.style.border = ""; // Reinicia el borde si es válido
            }

            if (this.esCorreoValido(this.email) &&
                this.tieneMinimoTresLetras(this.contenido)) {
                // emailjs.init("9D_viF6YKvAEF0eDM"); // Asegúrate de reemplazar "TU_USER_ID" con tu User ID

                emailjs.send("service_6xaa9ya", "template_0zsa4mf", {
                    emailjs_email: this.email,
                    emailjs_message: this.contenido,
                })
                    .then(() => {

                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Tu correo ha sido enviado correctamente",
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.email=''
                        this.contenido=''
                        // console.log('SUCCESS!', response.status, response.text);
                    }, (error) => {
                        // console.log('FAILED...', error);
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: error,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    });


            }




        },
        esCorreoValido(correo) {
            // Expresión regular para validar correo electrónico
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(correo);
        },
        tieneMinimoTresLetras(texto) {
            // Expresión regular para validar que haya al menos tres letras
            const regex = /[a-zA-Z].*[a-zA-Z].*[a-zA-Z]/;
            return regex.test(texto);
        }

    }
}
</script>

<style scoped>
.contactanos {
    background-color: #e9e9e9;
    border-radius: 5px;
}

iframe {
    border-radius: 5px;
}

.formulario {
    width: 80%;
    border-radius: 10px;
    padding: 40px;
    background-color: #f9bb4f;
    color: white;
}

#mapa {
    height: 760px;

}

/* Estilo para pantallas más pequeñas */
@media only screen and (max-width: 1200px) {
    #mapa {
        height: 66vh;
        /* 60% de la altura de la ventana */
    }
}

@media only screen and (max-width: 990px) {
    #mapa {
        height: 50vh;
        /* 50% de la altura de la ventana */
    }

    iframe {
        margin-block: 50px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    #mapa {
        height: 36vh;
        /* 40% de la altura de la ventana */
    }
}

@media only screen and (max-width: 572px) {
    #mapa {
        height: 38vh;
        /* 30% de la altura de la ventana */
    }
}

@media only screen and (max-width: 470px) {
    #mapa {
        height: 30vh;
        /* 30% de la altura de la ventana */
    }
}

.mapa-contenedor {
    padding-bottom: 120px;
}
</style>
