<template>
    <!-- <div class="d-flex justify-content-center" style="width:100%; ">
        <img src="@/assets/EnConstruccion-transformed.jpeg" style="border-radius: 15px; width:65%;" alt="Página Ahdeco">
    </div> -->


    <div class="container">
        <h2 class="mb-5 display-3 bebas-neue-regular mt-4 text-center">Servicios</h2>

        <div class="row hidden" id="emprendimiento">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 emprendimiento-info" id="creacion-pagina">
                <h4>Creación de Página Web</h4>
                <p>Transforma tu presencia online con páginas web impresionantes y <strong>responsive</strong> que
                    brindan una experiencia perfecta en cualquier dispositivo. Además, optimizamos cada página con un
                    <strong>SEO de calidad</strong> para mejorar tu visibilidad y atraer más tráfico, ayudando a hacer
                    crecer tu negocio y marca.</p>

                <p>Con nosotros, tu página web será una poderosa herramienta de <strong>crecimiento y éxito
                        digital</strong>.</p>

            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/emprendimiento-comunitario.jpeg"
                    alt="Creacion pagina web por axum">
            </div>
        </div>
        <div class="row hidden" id="gobierno">
            <div class="col-12 order-2 order-sm-1 col-sm-6 p-5 ">
                <img class="img-empre" src="@/assets/gobierno.jpeg" alt="Desarrollo de software por axum">
            </div>
            <div
                class="col-12 order-1 order-sm-2 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 gobierno-info">
                <h4 class="mt-3">Desarrollo de software</h4>
                <p>Contamos con un equipo de <strong>programadores altamente capacitados</strong>, especializados en
                    desarrollar <strong>soluciones digitales a medida</strong>. Ofrecemos la creación de
                    <strong>plataformas web personalizadas</strong>, <strong>bots de WhatsApp</strong> y otras
                    soluciones que abarcan tanto el <strong>backend</strong> como el <strong>frontend</strong>.
                </p>

                <p>Nos aseguramos de que cada plataforma sea <strong>robusta</strong>, escalable y fácil de usar,
                    adaptada a las necesidades de su empresa. Con nuestra experiencia, podemos optimizar sus procesos y
                    mejorar la experiencia de usuario, contribuyendo al crecimiento y eficiencia de su negocio.</p>

            </div>

        </div>
        <div class="row hidden" id="comunidad">
            <div class="col-12 col-sm-6 d-flex text-justify justify-content-center flex-column p-5 comunidad-info">
                <h4 class="mt-3">Venta de Hardware</h4>
                <p>Ofrecemos una amplia gama de <strong>hardware nuevo</strong> de <strong>excelente calidad</strong>,
                    incluyendo <strong>laptops</strong>, <strong>componentes</strong>, <strong>monitores</strong>,
                    <strong>mouses</strong>, <strong>teclados</strong> y más, de las <strong>mejores marcas</strong> del
                    mercado. Cada producto ha sido seleccionado cuidadosamente para garantizar un alto rendimiento y
                    durabilidad, adaptándose a las necesidades tanto de empresas como de usuarios particulares.
                </p>

                <p>Confíe en nosotros para equipar su entorno de trabajo con tecnología de vanguardia que optimiza la
                    productividad y mejora la experiencia del usuario.</p>

            </div>
            <div class="col-12 col-sm-6 p-5">
                <img class="img-empre" src="@/assets/comunidad.jpeg" alt="Venta de pc por axum">
            </div>
        </div>



    </div>

</template>

<script>
export default {

}
</script>

<style>
.text-justify {
    text-align: justify;
}

.img-empre {
    width: 100%;
    border-radius: 5px;
}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #F9B233;
}

.emprendimiento-info {
    /* border-bottom: 4px solid #158B37; */
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}

.gobierno-info {
    /* border-bottom: 4px solid #1E83C5;        */
    border-right: 4px solid #1E83C5;
    /* border-radius: 5px; */
}

.comunidad-info {
    /* border-bottom: 4px solid #E20413;    */
    border-left: 4px solid #F6A500;
    /* border-radius: 5px; */
}
</style>