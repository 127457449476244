<template>
    <div class="container d-flex justify-content-center">
        <img src="@/assets/EnConstruccion.jpg" alt="">
    </div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>