<template>
  <div class="franja-saber">
    <img src="@/assets/img2.jpg" alt="">
    <div class="overlay"></div>
    <div class="text">¿Quién es AXUM?</div>
  </div>

  <section class="container mt-5 p-0 p-lg-5 ">
    <div class="row text-center">
      <div class="about-us" style="text-align: justify;">
        <p>
          En <strong>AXUM</strong>, somos un equipo apasionado por la <strong>tecnología</strong> y el
          <strong>desarrollo de soluciones innovadoras</strong> que transforman la forma en que las empresas y personas
          utilizan el software y el hardware. Nos enfocamos en comprender las necesidades de nuestros clientes para
          crear <strong>experiencias personalizadas</strong> y <strong>soluciones eficaces</strong>.
        </p>
        
        <p>
          Con una sólida trayectoria en el sector tecnológico, en AXUM combinamos <strong>conocimiento</strong> y
          <strong>experiencia</strong> para ofrecer servicios de calidad y productos de última generación que permiten a
          nuestros clientes avanzar en el entorno digital actual.
        </p>
      </div>
    </div>
  
  </section>

</template>

<script>
export default {
  data() {
    return {
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
    }
  },
  methods: {

  }
}
</script>

<style>
strong{
  font-weight: 600;
}
.franja-saber {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: black;
}

.franja-saber img {
  position: absolute;
  top: -190px;
  /* Mueve la imagen hacia arriba para mostrar solo la parte inferior */
  left: 0;
  filter: blur(10px);
  width: 100%;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Centra el texto */
  color: white;
  font-size: 45px;
  font-weight: bold;
  z-index: 2;
  /* Asegura que el texto esté delante de la imagen */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.655);
  /* Capa oscura con opacidad */
  z-index: 2;
  /* Asegura que la capa oscura esté encima de la imagen */
}

.tree-box {
  margin-top: 60px;
  /* background-color: rgba(0, 0, 0, 0.114); */
  border-radius: 5px;
}

.box {
  height: 100%;
  width: 100%;
  background-color: rgb(31, 31, 31);
  background-color: rgb(31, 31, 31);
  padding-top: 40px !important;
  padding-bottom: 40px !important;

}

.box p {
  text-align: justify;
  padding: 6px;
}

.white {
  text-align: justify;

}

.iconos {
  font-size: 60px;
  margin: 20px;
}

.box-2 {

  /* border-top: 2px solid #F6A500; */
  /* Borde superior de 2 píxeles de grosor y color negro */
  /* border-left: 2px solid #1E83C5; */
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 10px;
  color: #000;

}

.box-2-l {

  /* border-top: 2px solid #F6A500; */
  /* border-bottom: 2px solid #1E83C5; */
  /* Borde superior de 2 píxeles de grosor y color negro */
  /* border-right: 2px solid #F6A500; */
  /* border-left: 2px solid #1E83C5; */
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 5px;
  color: #000;
  background-color: #ededed;

}

.box-2:hover {

  border-top: 2px solid #F6A500;
  /* Borde superior de 2 píxeles de grosor y color negro */
  border-left: 2px solid #1E83C5;
  /* Borde izquierdo de 2 píxeles de grosor y color negro */
  border-radius: 10px;
  color: #000;

}


.valores {
  /* color: #152f8b; */
  margin-top: 45px;
  font-size: 45px;
}

.valores-casillas {
  /* color: #152f8b; */
  width: 100%;
  height: 75px;
  background-color: #158B37;
  padding: 10px;
  color: white;
  font-size: 16px;
  border: 2px solid #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.valores-casillas:hover {
  background-color: rgb(34, 184, 14);
}

.valores-casillas i {
  font-size: 25px;
}

.mostrar-info {
  padding: 15px;
}

@media only screen and (max-width: 1200px) {
  #quienes-somos {
    padding: 35px;
  }
}
</style>